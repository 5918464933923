import { useEffect, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { tiempoRecargaEstilosCaptchaV3, UMBRAL_ANCHO_VENTANA } from 'utils/constants';

const CaptchaV3 = ({ onVerify }: { onVerify: any }) => {
  /**
   * Clave de Google reCAPTCHA desde el entorno de variables
   */
  const claveGoogleCaptchaV3 = process.env.REACT_APP_CAPTCHA_KEY_V3 || '';

  /**
   * Estado para rastrear el ancho actual de la ventana
   */
  const [anchoVentana, setAnchoVentana] = useState(window.innerWidth);

  /**
   * Obtiene el valor del estado del captcha v3
   */
  const estadoCaptchaV3 = useSelector((state: RootState) => state.desconexion.captchaV3Estado);

  /**
   * UseEffect manejador de cambio de tamaño de pantalla
   */
  useEffect(() => {
    const manejarCambioTamaño = () => {
      const nuevoAncho = window.innerWidth;
      if (
        (anchoVentana <= UMBRAL_ANCHO_VENTANA && nuevoAncho > UMBRAL_ANCHO_VENTANA) ||
        (anchoVentana > UMBRAL_ANCHO_VENTANA && nuevoAncho <= UMBRAL_ANCHO_VENTANA)
      ) {
        window.location.reload();
      }
      setAnchoVentana(nuevoAncho);
    };

    window.addEventListener('resize', manejarCambioTamaño);
    return () => {
      window.removeEventListener('resize', manejarCambioTamaño);
    };
  }, [anchoVentana]);

  /**
   * UseEffect para esconder el captcha v3
   */
  useEffect(() => {
    /**
     * Manejador de estilo del elemento grecaptcha-badge basado en estadoCaptchaV3
     */
    const modificarEstiloBadge = () => {
      const badgeElement = document.querySelector('.grecaptcha-badge') as HTMLElement;

      if (badgeElement) {
        if (!estadoCaptchaV3) {
          badgeElement.style.height = '0';
          badgeElement.style.overflow = 'hidden';
        } else {
          badgeElement.style.height = '';
          badgeElement.style.overflow = '';
        }
      } else {
        setTimeout(modificarEstiloBadge, tiempoRecargaEstilosCaptchaV3); // Vuelve a intentar después de 100ms si el elemento no está disponible
      }
    };

    modificarEstiloBadge();
  }, [estadoCaptchaV3]);

  if (!claveGoogleCaptchaV3) {
    return null;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={claveGoogleCaptchaV3} data-testid="recaptcha-v3">
      <GoogleReCaptcha onVerify={onVerify} />
    </GoogleReCaptchaProvider>
  );
};

export default CaptchaV3;
